import _map from 'lodash/map';
import React, { memo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// assets
import app_store from 'assets/img/footer/app-store.svg';
import apple from 'assets/img/footer/apple-logo.svg';
import arrow_down_light from 'assets/img/footer/arrow_down_light.svg';
import call from 'assets/img/footer/call.svg';
import g_play from 'assets/img/footer/google-play.svg';
import instagram_light from 'assets/img/footer/instagram_light.svg';
import linux from 'assets/img/footer/linux.svg';
import medium_light from 'assets/img/footer/medium_light.svg';
import chat from 'assets/img/footer/messages.svg';
import pinterest_light from 'assets/img/footer/pinterest_light.svg';
import email from 'assets/img/footer/sms.svg';
import telegram_light from 'assets/img/footer/telegram_light.svg';
import windows from 'assets/img/footer/windows8.svg';
import x from 'assets/img/footer/x.svg';
import Logo from 'assets/img/logo.svg';
import { FooterContact, FooterMainField } from './footer.style';

// component
import { H4 } from 'cly-uikit/elements/text/headings';
import P from 'cly-uikit/elements/text/P';
import Menu from 'utils/navbar.config';

import packageJson from '../../../../package.json';

function Footer() {
	const { t } = useTranslation('footer');
	const { i18n } = useTranslation();
	const [openSection, setOpenSection] = useState('');
	const menu = Menu();
	let language = i18n.language;

	const setOpenSectionClickHandler = (section: any) => {
		if (section === openSection) {
			setOpenSection('');
		} else {
			setOpenSection(section);
		}
	};

	const openChatHandler = () => {
		document.querySelector<HTMLElement>('[data-id]')?.click();
	};

	return (
		<FooterMainField>
			<div className='footer_flex_container'>
				<div className='footer_container'>
					{_map(menu, (value, index) => (
						<React.Fragment key={index}>
							{!value.isNavOnly ? (
								<div className={`column_container column_${value.title.toLowerCase()}`}>
									<div className='column_header' onClick={() => setOpenSectionClickHandler(value.title)}>
										<H4>{value.title}</H4>
										<img src={arrow_down_light} className='arrow' alt='' />
									</div>
									<div className={`footer_links_container ${openSection === value.title ? 'active' : ''}`}>
										{_map(value.subMenu, (sub, index) => (
											<Link className='footer_item' to={`${language}${sub.link}`} key={index}>
												{sub.title}
											</Link>
										))}
									</div>
								</div>
							) : null}
						</React.Fragment>
					))}
					<FooterContact>
						<div className='column_container'>
							<div className='column_header'>
								<H4 className='contacts'>{t('Contacts')}</H4>
							</div>
							<div className='footer_links_container'>
								<a className='footer_item contacts_item' href='mailto:support@coinlocally.forex'>
									<img src={email} alt='contacts' />
									<span>support@coinlocally.forex</span>
								</a>
								<p className='footer_item contacts_item' onClick={openChatHandler}>
									<img src={chat} alt='contacts' />
									<span>{t('Live Support')}</span>
								</p>
								<a className='footer_item contacts_item' href='tel:+37410239997'>
									<img src={call} alt='contacts' />
									<span>+37410239997</span>
								</a>
							</div>
						</div>
						<div className='column_container'>
							<div className='platforms'>
								<div className='column_header'>
									<H4 className='title_desktop'>{t('MetaTrader 5 Trading Platform')}</H4>
									<H4 className='title_phone'>{t('MT5 Download')}</H4>
								</div>
								<div className='footer_platforms'>
									<div className='platform_items'>
										<a
											href='https://download.mql5.com/cdn/web/coinlocally.llc/mt5/coinlocally5setup.exe'
											target='_blank'
											rel='noreferrer'
										>
											<img src={windows} alt='platform_icon' />
										</a>
										<a
											href='https://www.metatrader5.com/en/terminal/help/start_advanced/install_mac'
											target='_blank'
											rel='noreferrer'
										>
											<img src={apple} alt='platform_icon' />
										</a>
										<a
											href='https://www.metatrader5.com/en/terminal/help/start_advanced/install_linux'
											target='_blank'
											rel='noreferrer'
										>
											<img src={linux} alt='platform_icon' />
										</a>
										<a
											href='https://download.mql5.com/cdn/mobile/mt5/android?server=Coinlocally-Server'
											target='_blank'
											rel='noreferrer'
										>
											<img src={g_play} alt='platform_icon' />
										</a>
										<a
											href='https://download.mql5.com/cdn/mobile/mt5/ios?server=Coinlocally-Server'
											target='_blank'
											rel='noreferrer'
										>
											<img src={app_store} alt='platform_icon' />
										</a>
									</div>
								</div>
							</div>
						</div>
					</FooterContact>
				</div>

				<div className='forex_container'>
					<img src={Logo} alt='coinlocally-forex' />
					<P>
						<Trans components={{ br: <br /> }}>{t('about_us')}</Trans>
					</P>
				</div>

				<div className='risk_container'>
					<div className='disclaimer'>
						<div className='risk_title'>{t('Risk Disclaimer')}</div>
						<P>
							<Trans components={{ br: <br /> }}>{t('risk_disclaimer')}</Trans>
						</P>
					</div>
				</div>
				<div className='footer_copy_socials'>
					<div className='footer_socials_container'>
						<div className='social_items'>
							<a href='https://t.me/Coinlocally_fx' target='_blank' rel='noopener noreferrer' className='footer_social_item'>
								<img src={telegram_light} alt='social_icon' />
							</a>
							<a
								href='https://instagram.com/coinlocally/'
								target='_blank'
								rel='noopener noreferrer'
								className='footer_social_item'
							>
								<img src={instagram_light} alt='social_icon' />
							</a>
							<a
								href='https://www.pinterest.com/coinlocally_exchange/'
								target='_blank'
								rel='noopener noreferrer'
								className='footer_social_item'
							>
								<img src={pinterest_light} alt='social_icon' />
							</a>
							<a href='https://x.com/fxcoinlocally' target='_blank' rel='noopener noreferrer' className='footer_social_item'>
								<img src={x} alt='social_icon' />
							</a>
							<a
								href='https://medium.com/coinlocally.exchange'
								target='_blank'
								rel='noopener noreferrer'
								className='footer_social_item'
							>
								<img src={medium_light} alt='social_icon' />
							</a>
						</div>
					</div>
					<small className='footer_copyright'>
						© {new Date().getFullYear()}
						{t(', Coinlocally. All Rights Reserved.')}
						-v{packageJson.version}
					</small>
					<p className='holder' />
				</div>
			</div>
		</FooterMainField>
	);
}

export default memo(Footer);
