import { useState, type FC } from 'react';
import type { NavbarProps } from 'shared/types/layout.type';
import { useTranslation } from 'react-i18next';
import { NavbarContainer } from './navbar.style';
import Menu from 'utils/navbar.config';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/img/logo.svg';
import NavbarItem from './navbar-item';
import { MenuOutline, UserOutline } from '@coinlocally/cly-core/icons';
import HamburgerMenu from './hamburger-menu';
import { Button, ChangeLanguageModal, ClyCoreConfig } from '@coinlocally/cly-core';
import { useAppSelector, useBreakpoint } from 'shared/hooks';
import Profile from './profile';

const Navbar: FC<NavbarProps> = ({ navbarType = 'FULL' }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [hamburgerStatus, setHamburgerStatus] = useState<'profile' | 'general' | ''>('');
	const [lngModalStatus, setLngModalStatus] = useState<boolean>(false);
	const isLogin = useAppSelector(store => store.Profile.isLogin);
	const { i18n } = useTranslation('navbar');
	const menu = Menu();
	const isTablet = useBreakpoint('max', 'xl');

	const onChangeLanguageHandler = (lng: string) => {
		if (lng !== i18n.language) {
			ClyCoreConfig.LANGUAGE = lng as 'en' | 'ru' | 'fa' | 'ar' | 'de' | 'es';
			navigate(location.pathname.replace(i18n.language, lng), { replace: true });
			i18n.changeLanguage(lng);
		}
	};

	return (
		<NavbarContainer>
			{navbarType === 'FULL' ? (
				<div className='top_container'>
					{isTablet ? (
						isLogin ? (
							<Button className='left_button' variant='outline' size='small' href={`/${i18n.language}/dashboard/accounts`}>
								Try Demo
							</Button>
						) : (
							<Button className='left_button' variant='outline' size='small' href={`/${i18n.language}/login`}>
								Login
							</Button>
						)
					) : (
						<>
							<Link to={`/${i18n.language}/dashboard/accounts`} className='demo_link'>
								Try Demo
							</Link>

							{isLogin ? null : (
								<Button className='left_button' variant='outline' size='small' href={`/${i18n.language}/login`}>
									Login
								</Button>
							)}
						</>
					)}

					<Button className='right_button' variant='secondary' size='small' href={`/${i18n.language}/dashboard/accounts`}>
						Open Live
					</Button>
				</div>
			) : null}

			<div className='bottom_container'>
				<div className='left_field'>
					<Link to={`/${i18n.language}`}>
						<img src={Logo} alt='' />
					</Link>

					{navbarType === 'FULL' ? (
						<div className='menu_list'>
							{menu.map((item: any, index: any) => (
								<NavbarItem
									title={item.title}
									subMenu={item.subMenu ?? null}
									link={item.link}
									href={item.href}
									key={`navbar_item_${index}`}
								/>
							))}
						</div>
					) : null}
				</div>

				<div className='right_field'>
					{navbarType === 'FULL' ? (
						<>
							{isLogin ? (
								<>
									{isTablet ? (
										<button className='icon_button' onClick={() => setHamburgerStatus('profile')}>
											<UserOutline />
										</button>
									) : (
										<Profile />
									)}
								</>
							) : null}
							<div className='divider' />
						</>
					) : null}

					{/* {!isTablet ? (
						<button className='icon_button' onClick={() => setLngModalStatus(true)}>
							<GlobalOutline />
						</button>
					) : (
						<button className='icon_button' onClick={() => setHamburgerStatus('general')}>
							<MenuOutline />
						</button>
					)} */}
					{isTablet && (
						<button className='icon_button' onClick={() => setHamburgerStatus('general')}>
							<MenuOutline />
						</button>
					)}
				</div>
			</div>

			{lngModalStatus ? (
				<ChangeLanguageModal status={lngModalStatus} setStatus={setLngModalStatus} onLanguageClick={onChangeLanguageHandler} />
			) : null}

			{hamburgerStatus !== '' ? (
				<HamburgerMenu
					status={['general', 'profile'].includes(hamburgerStatus)}
					onClose={() => setHamburgerStatus('')}
					type={hamburgerStatus}
				/>
			) : null}
		</NavbarContainer>
	);
};

export default Navbar;
